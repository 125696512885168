<template>
  <div class="task_content">
    <div class="task_content-top">
      <div class="task_content_logo">
        <div  class="current_task" v-if="tab">
          <img src="@/assets/images/task-content-logo.png" alt="">
          <div class="task">
            <div class="task_detail">
              <span class="font_style">当前任务</span>
              <div
                class="task_title"
                :title="currentTask.title">
                {{currentTask.title}}
              </div>
              <div class="task_time">
                <span class="font_style">任务完成时限:</span>
                <span class="font-style-two">{{currentTask.deadline}}</span>
              </div>
              <div class="task_time">
                <el-button
                  type="primary"
                  size="small"
                  @click="toDetail">
                  查看任务
                </el-button>
                <p class="font_style1 ">企业完结任务三天后，奖励发放到账号</p>
              </div>
            </div>
          </div>
        </div>
        <div class="no_task" v-else>
          <p>暂无任务!</p>
        </div>
      </div>
    </div>
    <div class="task-table">
      <div class="task-history">
        <img src="@/assets/images/history-logo.png" alt="" class="task-history-message-img">
        <span class="task-history-message">历史任务</span>
      </div>
      <div class="task_history_table">
          <el-table :data="dataList"
            @row-click="historyToDetail">
            <el-table-column
              prop="id"
              type="index"
              :index="indexMethod"
              label="任务编号"
              width="100px">
            </el-table-column>
            <el-table-column
              prop="title"
              label="任务名称">
            </el-table-column>
            <el-table-column
              prop="amount"
              label="任务奖励">
            </el-table-column>
            <el-table-column
              prop="level"
              label="任务级别">
              <template slot-scope="scope">
                <el-tag
                  :type="{
                    [TASK_LEVEL.COMMON]: 'success',
                    [TASK_LEVEL.GENERAL]: 'info',
                    [TASK_LEVEL.COMPLEX]: 'warning',
                    [TASK_LEVEL.DIFFICULTY]: 'danger'
                  }[scope.row.level]">
                  {{
                    {
                      [TASK_LEVEL.COMMON]: '普通',
                      [TASK_LEVEL.GENERAL]: '一般',
                      [TASK_LEVEL.COMPLEX]: '复杂',
                      [TASK_LEVEL.DIFFICULTY]: '困难'
                    }[scope.row.level]
                  }}
          </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="timeLimit"
              label="任务时限">
            </el-table-column>
            <el-table-column
              prop="deadline"
              label="任务截至时间(任务提交时间)">
            </el-table-column>
            <el-table-column
              label="任务状态">
              <template slot-scope="scope">
                <el-tag
                :type="{
                  [TASK_STATUS.AUDIT]: 'info',
                  [TASK_STATUS.REJECT]: 'danger',
                  [TASK_STATUS.CANCELED]: 'warning',
                  [TASK_STATUS.WAITING_FOR_REWARDS]: 'success',
                  [TASK_STATUS.SUCCESSFUL]: 'success',
                }[scope.row.status]">
                {{
                  {
                    [TASK_STATUS.AUDIT]: '待审核',
                    [TASK_STATUS.REJECT]: '已驳回',
                    [TASK_STATUS.CANCELED]: '已取消',
                    [TASK_STATUS.WAITING_FOR_REWARDS]: '等待奖励发放',
                    [TASK_STATUS.SUCCESSFUL]: '已完成',
                  }[scope.row.status]
                }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div class="task-pages">
            <pagination
              @paginationChange= "paginationChange"
              :pageInfo="pageInfo">
            </pagination>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'
import globalVar from '@/configs/globalVar'
import Pagination from '@/components/pagination/index.vue'
export default {
  components: {
    Pagination
  },
  data () {
    return {
      pageInfo: {
        pageNum: 1,
        pageSize: 5,
        total: 0
      },
      dataList: [],
      currentTask: {},
      tab: false
    }
  },
  computed: {
    TASK_LEVEL () {
      return globalVar.TASK_LEVEL
    },
    TASK_STATUS () {
      return globalVar.TASK_STATUS
    }
  },
  created () {
    this.historyTaskList()
    this.ongoingTask()
  },
  methods: {
    toDetail () {
      this.$router.push(`/user/current-task-detail/${this.currentTask.taskId}`)
    },
    historyToDetail (row) {
      this.$router.push(`/user/historic-task-detail/${row.id}`)
    },
    indexMethod (index) {
    // 当前页数 - 1 * 每页数据条数 + index + 1
      return (this.pageInfo.pageNum - 1) * this.pageInfo.pageSize + index + 1
    },
    paginationChange (val) {
      this.pageInfo.pageNum = val
      this.historyTaskList()
    },
    // 获取历史任务列表HistoryTaskList
    historyTaskList () {
      api.HistoryTaskList({
        ...this.pageInfo
      }).then(res => {
        if (res.data.code === 0) {
          const list = res.data.data.list
          const { total } = res.data.data
          this.pageInfo.total = total
          this.dataList = list
          this.pageInfo.pageNum = res.data.data.currentPage
        } else {
          this.$message.error('获取历史任务列表失败')
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求异常')
      })
    },
    // 展示正在进行中的任务ongoingTask
    ongoingTask () {
      api.currentTask().then(res => {
        if (res.data.code === 0) {
          if (res.data.data.taskStatus === 4) {
            this.tab = false
          } else {
            this.tab = true
          }
          this.currentTask = res.data.data
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求异常')
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.task_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .task_content-top {
    width: 100%;
    height: 240px;
    background: #FFFFFF;
    background-image: url('../../../../assets/images/task-right-logo.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    overflow: auto;
    .task_content_logo {
      padding: 30px 0px 30px 30px;
      .current_task {
        display: flex;
        justify-content: flex-start;
      }
      .task {
        padding-left: 20px;
        .task_detail {
          display:flex;
          flex-direction: column;
          justify-content: space-between;
          .font_style  {
            width: 56px;
            height: 20px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #909399;
          }
          .font-style-two {
            width: 58px;
            height: 20px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 0px;
            color: #3473E6;
            padding-left:15px;
          }
          .task_title {
            width: 350px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #303133;
            margin-top:10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .task_time {
            margin-top:20px;
            .font_style1 {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #909399;
            padding-top:5px;
          }
          }
        }
      }
      .no_task {
        width: 90px;
        height: 20px;
        line-height: 20px;
        font-weight: bold;
        margin: 0 auto;
        text-align: center;
      }
    }
    .task-detail-margin {
      margin-left:20px;
    }
  }
  .task-table {
    width: 100%;
    flex: 1;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin-top: 20px;
    padding: 18px 30px 0px 30px;
    .task-history {
      display: flex;
      justify-content: flex-start;
      .task-history-message-img {
        margin-right: 10px;
      }
    }
    .task-pages {
      margin-top: 10px;
      text-align: center;
    }
  }
}
</style>
